<template>
  <div>  
    <v-overlay
      :v-if="alertLogout"
      :value="alertLogout"
      :opacity="1"
    >
    <v-card>
    <v-card-title 
    class="ma-5px"
    >Willst du dich verpissen 😱?</v-card-title>
    <v-card-text>
      <v-btn
        class="logoutButton"
        @click="logout"
        elevation="2"
      >
      <v-icon large>mdi-check</v-icon>
      </v-btn>
      <v-btn
        class="logoutButton"
        @click="alertLogout = false"
        elevation="2"
      >
      <v-icon large>mdi-close</v-icon>
      </v-btn>
      </v-card-text>
    </v-card>
   </v-overlay>
    <v-app-bar
        class="primary"
        fluid>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>
        EASY WAY
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon
        @click="alertLogout = true"
        >
        <v-icon>mdi-export</v-icon>
        </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      class="primary"
      app
       absolute
      temporary
    >
      <v-list
        nav
      >
        <v-list-item-group
          active-class="accent"
        >
          <v-list-item :to="{name: 'Dashboard'}" >
            <v-list-item-title >Dashboard</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{name: 'Events'}" >
            <v-list-item-title  >Events</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{name: 'Calendar'}" >
            <v-list-item-title >Kalender</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{name: 'Persons'}">
            <v-list-item-title>Personen</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
      <v-list-item two-line>
          <v-list-item-avatar>
            <img src="https://icons.r2v.ch/wolf.png">
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{$store.state.user.username}}</v-list-item-title>
            <v-list-item-subtitle>{{$store.state.user.role}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
  export default {
     data () {
      return {
        alertLogout: false,
        drawer: false,
      }
    },
    methods:{
    logout(){
      sessionStorage.removeItem('EAtoken');
      this.$store.dispatch('logout');
      this.alertLogout = false;
      this.$router.replace({name:'Login'});
      },
    },
  }
</script>
<style scoped>
.logoutButton{
  margin: 20px;
}
</style>