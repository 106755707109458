<template>
  <v-app id="app">
    <v-overlay
        :value="$store.state.reLoginActive"
    >
      <v-progress-circular
          size="200"
          indeterminate
      > Wart hurti..

      </v-progress-circular>
    </v-overlay>
    <Nav v-if="$store.state.user.username" />
    <v-main >
    <router-view class="pa-8" />
    </v-main>
  </v-app>
</template>
<script>
import Nav from "@/views/Nav.vue";

export default {
  name: 'App',
  components: {
    Nav,
  },
  async created(){
    this.$store.state.reLoginActive = true;
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) throw Error("user expired");
      let distance = new Date(user.expiresAt).getTime() - new Date().getTime();
      if (distance < 0) {
          throw Error("user expired");
      } else {
            this.$store.commit("setUser", user);
            await this.$store.dispatch("fetchEvents");
            await this.$store.dispatch("fetchPersons");
      }
      this.$store.state.reLoginActive = false;
    } catch (err) {
      console.error(err.message);
      this.$store.dispatch('logout')
      this.$store.state.reLoginActive = false;
      if(this.$router.from !== `/login`) this.$router.replace('/login')
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
* {
  margin: 0;
  padding: 0;
}
</style>
